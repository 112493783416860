import { transformToHTML5 } from '@utils/html';
import clsx from 'clsx';
import React from 'react';

type Props = { html?: string; className?: string };

function RawHtml(props: Props) {
  const { html = '', className } = props;

  return (
    <div
      className={clsx('flow', className)}
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{ __html: transformToHTML5(html) }}
    />
  );
}

export default RawHtml;
