import type { WagtailMainMenu } from '@/types';
import { WagtailImage } from '@/types';
import Navigation from '@components/Navigation';
import { addBaseUrl } from '@utils/url';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef } from 'react';

type HeaderProps = {
  ariatext: string;
  image: WagtailImage;
  warning: string;
};

type Props = {
  menu: WagtailMainMenu;
  header: HeaderProps;
};

function Header(props: Props) {
  const { menu, header } = props;
  const headerRef = useRef<HTMLHeadElement | null>(null);
  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 5) {
      headerRef.current?.classList.add('scroll');
    } else {
      headerRef.current?.classList.remove('scroll');
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
    <header ref={headerRef} className='header'>
      <div className='centercontainer'>
        {header?.image ? (
          <Link href='/' className='header__logo'>
            <Image
              src={addBaseUrl(header.image.meta.download_url)}
              alt={header?.image?.title}
              width={158}
              height={42}
            />
          </Link>
        ) : null}

        <Navigation data={menu} />
      </div>
    </header>
  );
}

export default Header;
