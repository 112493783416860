import { WagtailImage } from '@/types';
import { addBaseUrl, removeBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml';
import Image from 'next/image';
import Link from 'next/link';

type SocialMediaData = {
  image: WagtailImage | null;
  link: string;
  title: string;
  id: number;
  svg: string | null;
  ariatext: string;
};

type FooterMenuData = {
  id: number;
  title: string;
  link_intern_full_url: string;
};

type FooterData = {
  id: number;
  text: string | null;
  social_media: SocialMediaData[];
  footer_menu: FooterMenuData[];
};

type Props = {
  data: FooterData | null;
};

function Footer(props: Props) {
  const { data } = props;
  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <footer className='footer centercontainer'>
      <div className='container'>
        {data?.text ? <RawHtml className='footer__text' html={data.text} /> : null}

        {data?.social_media?.length ? (
          <div className='footer__social'>
            {data?.social_media.map(item => (
              <Link
                key={item.id}
                href={removeBaseUrl(item.link)}
                className='footer__socialitem'
                aria-label={item.ariatext}
                target='_blank'
                rel='noreferrer'
              >
                {item.image ? (
                  <Image
                    src={addBaseUrl(item.image.meta.download_url)}
                    alt={item.image.title}
                    className='socialicon'
                    width={40}
                    height={40}
                  />
                ) : null}
              </Link>
            ))}
          </div>
        ) : null}
        <div className='footer__menu'>
          {data?.footer_menu.length ? (
            <ul className='footer__menu-list'>
              {data.footer_menu.map(entry => (
                <li key={entry.id} className='footer__menu-list-item'>
                  <Link
                    href={removeBaseUrl(entry.link_intern_full_url)}
                    className='footer__socialitem'
                    rel='noreferrer'
                  >
                    {entry.title}
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}

          <p className='footer__copy'>© {date} Busche Zeltanlagen</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
