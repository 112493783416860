import Link from 'next/link';
import type { WagtailMainMenu } from '@/types';
import React, { useEffect, useRef } from 'react';
import { removeBaseUrl } from '@utils/url';
import Hamburger from '@components/Hamburger';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useSelectedLayoutSegment } from 'next/navigation';

type Props = {
  data: WagtailMainMenu;
};

function Navigation(props: Props) {
  const { data } = props;
  const navRef = useRef<HTMLElement | null>(null);
  const router = useRouter();

  useEffect(() => {
    function handleRouteChange() {
      navRef.current?.classList.remove('nav--open');
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router]);

  if (!data?.items?.length) return null;

  return (
    <>
      <nav className='nav' id='main-nav' aria-label='Hauptnavigation' ref={navRef}>
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
        <ul role='list' className='nav__list'>
          {data.items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li className='nav__list-item' key={`${index}${item.page.id}`}>
              <Link
                href={removeBaseUrl(item.href)}
                className={clsx('nav__link', {
                  active: router.asPath.endsWith(`/${item.page.slug}`),
                  'active-in-path':
                    router.asPath.startsWith(`/${item.page.slug}`) &&
                    router.asPath.length > item.page.slug.length + 1,
                })}
              >
                {item.text ?? item.page.title}
              </Link>
              {item.children.length ? (
                // eslint-disable-next-line jsx-a11y/no-redundant-roles
                <ul role='list' className='nav__sub-menu'>
                  {item.children.map((child, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li className='nav__sub-menu-item' key={`${i}${child.page.id}`}>
                      <Link
                        href={removeBaseUrl(child.href)}
                        className={clsx('nav__link', {
                          active: router.asPath.endsWith(`/${child.page.slug}`),
                        })}
                      >
                        {child.text ?? child.page.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </nav>
      <Hamburger
        onClick={() => {
          navRef?.current?.classList.toggle('nav--open');
        }}
        aria-label='Navigation umschalten'
        aria-controls='main-nav'
      />
    </>
  );
}

export default Navigation;
