import { PropsWithChildren } from 'react';

type Props = {};

function Main(props: PropsWithChildren<Props>) {
  const { children } = props;

  return <main className='main centercontainer'>{children}</main>;
}

export default Main;
