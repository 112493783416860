import CustomScripts from '@components/CustomScripts';
import Footer from '@components/Footer';
import Header from '@components/Header';
import Main from '@components/Main';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type Props = {};

function Layout(props: PropsWithChildren<Props>) {
  const { children } = props;

  return (
    <div className={clsx('layout')}>
      <Header
        /** @ts-expect-error */
        menu={children.props.menuData}
        /** @ts-expect-error */
        header={children.props.headerData}
      />
      <Main>{children}</Main>
      {/** @ts-expect-error */}
      <Footer data={children.props.footerData} />
      <CustomScripts />
    </div>
  );
}

export default Layout;
