import React, { HTMLAttributes } from 'react';

type Props = {} & HTMLAttributes<HTMLButtonElement>;

const Hamburger = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  return (
    <button className='hamburger' ref={ref} {...props}>
      <svg fill='var(--hb-color)' viewBox='0 0 100 100' width='50'>
        <rect className='line line__top' width='80' height='10' x='10' y='25' rx='5' />
        <rect className='line line__middle' width='80' height='10' x='10' y='45' rx='5' />
        <rect className='line line__bottom' width='80' height='10' x='10' y='65' rx='5' />
      </svg>
    </button>
  );
});

Hamburger.displayName = 'Hamburger';

export default Hamburger;
