import type { AppProps } from 'next/app';
import '../styles/style.scss';

import { Open_Sans } from '@next/font/google';
import Layout from '../components/Layout';

export const opensans = Open_Sans({ subsets: ['latin'] });

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${opensans.style.fontFamily};
        }
      `}</style>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}

export default MyApp;
